<template>
  <v-app-bar max-height="54px" height="54px" fixed class="app-bar-custom">
    <div class="d-flex align-center" v-if="$vuetify.theme.dark">
      <!--       <v-img
        src="../assets/nav-logo-dark.png"
        alt="Logo Unistar Elections"
        contain
        transition="scale-transition"
        width="40px"
        class="shrink ml-4"
      /> -->
      <v-img
        src="../assets/logo-branca.png"
        alt="Logo Unistar Elections"
        contain
        width="150"
        class="shrink mx-2"
      />
    </div>

    <div v-else class="d-flex align-center">
      <!--       <v-img
        src="../assets/nav-logo.png"
        alt="Logo Unistar Elections"
        contain
        transition="scale-transition"
        width="40px"
        class="shrink ml-4"
      /> -->
      <v-img
        src="../assets/logo-azul.png"
        alt="Logo Unistar Elections"
        contain
        width="150"
        class="shrink mx-2"
      />
    </div>

    <v-spacer></v-spacer>

    <div class="d-flex align-center mx-2">
      <v-icon class="mr-4">mdi-white-balance-sunny</v-icon>
      <v-switch
        v-model="$vuetify.theme.dark"
        inset
        hide-details
        color="primary"
      ></v-switch>
      <v-icon>mdi-weather-night</v-icon>
    </div>

    <v-menu left bottom offset-y transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-icon class="hidden-sm-and-down">mdi-account-outline</v-icon>
          <v-icon class="hidden-sm-and-down">mdi-chevron-down</v-icon>
          <v-icon class="hidden-md-and-up">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ userName }}</v-list-item-title>
            <v-list-item-subtitle>{{ userEmail }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon to="/user" v-bind="attrs" v-on="on">
                  <v-icon>mdi-account-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar perfil</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item to="/" link class="hidden-md-and-up" v-if="role">
          <v-list-item-title>Home</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item to="/dashboards" link class="hidden-md-and-up" v-if="role">
          <v-list-item-title>Dashboards</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item to="/connections" link class="hidden-md-and-up" v-if="role">
          <v-list-item-title>Bots</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi mdi-robot-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item to="/projects" link class="hidden-md-and-up" v-if="role">
          <v-list-item-title>Projetos</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-folder-multiple-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item to="/flows" link class="hidden-md-and-up" v-if="role">
          <v-list-item-title>Fluxos</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-sitemap-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item to="/intents" link class="hidden-md-and-up" v-if="role">
          <v-list-item-title>Bases de treinamentos</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-book-multiple-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item
          to="/service-queues"
          link
          class="hidden-md-and-up"
          v-if="role"
        >
          <v-list-item-title>Filas de atendimento</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-account-details-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item to="/chats" link class="hidden-md-and-up">
          <v-list-item-title>Atendimentos</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-message-text-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <!-- <v-list-item to="/sale-funnels" link class="hidden-md-and-up">
          <v-list-item-title>Funis de vendas</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-filter-outline</v-icon>
          </v-list-item-icon>
        </v-list-item> -->

        <v-list-item to="/lead-list" link class="hidden-md-and-up" v-if="role">
          <v-list-item-title>Contatos</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item to="/users" link v-if="isAdminOrsuperAdmin">
          <v-list-item-title>Usuários</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-account-cog-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>

        <!-- <v-divider></v-divider> -->

        <v-list-item to="/logout" link>
          <v-list-item-title>Sair</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import UserInfo from "@/helpers/UserInfo";

export default {
  props: {
    role: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      projects: [],
      isLoading: false,
      user: JSON.parse(localStorage.getItem('user'))
    };
  },
  computed: {
    userName() {
      return UserInfo.getUserName();
    },
    userEmail() {
      return UserInfo.getUserEmail();
    },
    isAdminOrsuperAdmin() {
      return this.user.role === 'admin' || 'super_admin'; 
    },
  },
};
</script>

<style scoped>
.logo-container {
  border: 3px solid rgb(219, 215, 215);
  border-left: 0px;
  border-top: 0px;
  border-bottom-right-radius: 20px;
  height: 100%;
  padding-right: 16px;
}
.v-app-bar .v-toolbar__content {
  padding: 0px !important;
}
.app-bar-custom {
  background-color: #fff !important;
  box-shadow: none !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

.app-bar-custom.theme--dark {
  background-color: #252525 !important;
  border-bottom: 1px solid #4e4e4e !important;
  box-shadow: none !important;
}
</style>

<template>
  <v-navigation-drawer app :expand-on-hover="sidebar" class="sidebar">
    <v-list nav dense>
      <v-list-item to="/" link v-if="role">
        <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ path: '/campaigns', query: { page: 1 } }" link v-if="role">
        <v-list-item-icon>
          <v-icon>mdi mdi-bullhorn-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Campanhas</v-list-item-title>
      </v-list-item>

      <v-list-item to="/connections" link v-if="role">
        <v-list-item-icon>
          <v-icon>mdi mdi-cellphone-check</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Conexões</v-list-item-title>
      </v-list-item>

      <v-list-item to="/groups" link v-if="role">
        <v-list-item-icon>
          <v-icon>mdi mdi-account-group-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Grupos</v-list-item-title>
      </v-list-item>

      <!-- <v-list-item to="/projects" link v-if="role">
        <v-list-item-icon>
          <v-icon>mdi-folder-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Projetos</v-list-item-title>
      </v-list-item> -->

      <!-- <v-list-item to="/flows" link v-if="role">
        <v-list-item-icon>
          <v-icon>mdi-sitemap-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Fluxos</v-list-item-title>
      </v-list-item> -->

      <!-- <v-list-item to="/intents" link v-if="role">
        <v-list-item-icon>
          <v-icon>mdi-book-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Bases de treinamentos</v-list-item-title>
      </v-list-item> -->

      <!-- <v-list-item to="/service-queues" link v-if="role">
        <v-list-item-icon>
          <v-icon>mdi-account-details-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Filas de atendimento</v-list-item-title>
      </v-list-item> -->

      <!-- <v-list-item to="/chats" link>
        <v-list-item-icon>
          <v-icon>mdi-message-text-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Atendimentos</v-list-item-title>
      </v-list-item> -->

      <!--       <v-list-item to="/sale-funnels">
        <v-list-item-icon>
          <v-icon>mdi-filter-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Funis de vendas</v-list-item-title>
      </v-list-item> -->

      <v-list-item :to="{ path: '/lead-list', query: { page: 1 } }" link v-if="role">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Contatos</v-list-item-title>
      </v-list-item>

      <v-expansion-panels flat class="ma-0 pa-0" v-if="isSuperAdmin">
      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <v-list-item>
            <v-list-item-icon>
              <v-icon left>mdi-account-star-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Administrador</v-list-item-title>
          </v-list-item>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item :to="{ path: '/admin/users', query: { page: 1 } }" link>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Usuários</v-list-item-title>
            </v-list-item>
            
            <v-list-item :to="{ path: '/admin/organization', query: { page: 1 } }" link>
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Organizações</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    </v-list>

    <template v-slot:append>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item @click="toggleSidebar" link>
          <v-list-item-icon>
            <v-icon>{{ sidebar ? "mdi-pin-outline" : "mdi-pin" }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ sidebar ? "Fixa" : "Desfixar" }} Menu
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <div class="version">versão {{ versionProject }}</div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import packageJson from "../../package.json";
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    role: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    versionProject() {
      return packageJson.version;
    },
    sidebar: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isSuperAdmin() {
      return this.user.role === 'super_admin'; 
    },     
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style scoped>
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 0;
}
.sidebar {
  height: calc(100% - 54px) !important;
  margin-top: 54px !important;
}

.theme--dark.v-navigation-drawer {
  background-color: #252525;
}

.sidebar>>>.theme--light.v-list-item:hover::before {
  opacity: 0.15;
}

.sidebar>>>.v-list-item--active {
  color: #89c4c7 !important;
}

/* .sidebar >>> .v-list-item--active::before {
  opacity: 0 !important;
} */

.sidebar>>>.theme--dark.v-list-item--active::before {
  opacity: 0 !important;
}

.sidebar>>>.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 12px;
}

.version {
  font-size: 12px;
  text-align: center;
}
</style>
